import React from "react"
import Player from "../../../blocks/en/player"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  player: playersYaml(slug: { eq: "lasha" }) {
    ...PlayerInfoEn
  }
  stats: allStatsYaml {
    nodes {
      key
      value_en
      average
    }
  }
}`

export default class Lasha extends Player {
  renderDescription() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Has a strong left foot and can do passes of any range. Easily gaining high speed with and without the ball. Always opens on the flank for the development of the attack, ready to receive the ball when the game approaches his zone. Good playmaker skills for his position, which allows him to play as a left defensive midfielder if necessary.</p>
          <p>Due to the mobility and vision compensates for the lack of anthropometric prowess. Positions himself correctly to ensure that the crosses from his flank do not reach the penalty area.</p>
          <p>Is confident in throw-ins, serves corners and free-kicks in a powerful manner with a significant curve.</p>
          <p>The Georgian national team scored two goals against Belarus after his corners from the right flank. Another goal after his set piece Georgia scored against Estonia.</p>
          <div className="video">
            <iframe
              title={ this.fullName() }
              src="https://www.youtube.com/embed/1GW4rtmJKn8"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </div>
        </div>
      </div>
    )
  }
}
